<template>
  <CRow class="reachability">
    <CCol cols="12" lg="12" class="pt-0 pb-0">
      <b-table class="data_table"
               ref="departmentTable" 
               :data="departmentData" 
               :striped="true" 
               :hoverable="true" 
               :mobile-cards="true" 
               :paginated="isPaginated" 
               :per-page="perPage" 
               :current-page.sync="currentPage" 
               :pagination-simple="isPaginationSimple" 
               :pagination-position="paginationPosition"
               :default-sort-direction="'desc'"
               :sort-icon="sortIcon"
               :sort-icon-size="sortIconSize"
               default-sort="seen_percentage">

        <template slot-scope="props">
          <b-table-column field="reach.department_name" sortable :label="$t('common.Department')" :searchable="searchEnabled" width="20%">
            <span>{{props.row.reach.department_name}}</span>
          </b-table-column>
          <b-table-column field="reach.headcount_reach" sortable :label="$t('common.Unique_views')">
            <div class="d-flex align-items-center count">
              <span>{{props.row.reach.headcount_reach}}</span>
            </div>
          </b-table-column>
          <b-table-column field="reach.average_post_amount" sortable :label="$t('insights.Average_posts')">
            <div class="d-flex align-items-center count">
              <span>{{props.row.reach.average_post_amount.toFixed(1)}}</span>              
            </div>
          </b-table-column>
          <b-table-column field="reach.headcount_publishers" sortable :label="$t('common.Publishers')">
            <div class="d-flex align-items-center count">
              <span>{{props.row.reach.headcount_publishers}}</span>
            </div>
          </b-table-column>
					<b-table-column v-for="sentiment in props.row.sentiment" v-bind:key="sentiment.like_tag" field="sentiment" :label="sentiment.like_type" centered>
            <template v-slot:header="{}">
              <span v-html="getTwemoji(sentiment.like_type)" class="th_image"></span>
            </template>            
						<span>{{ sentiment.like_count }}</span>
          </b-table-column>
          <b-table-column field="reach.percentage" sortable :label="$t('insights.Reach')" width="45%">
            <CProgress :max="100" show-value>
              <CProgressBar :value="props.row.reach.percentage"></CProgressBar>
              <span>{{parseFloat(props.row.reach.percentage).toFixed(0)}}%</span>
            </CProgress>
          </b-table-column>
        </template>
        <template slot="empty">
          <div class="p-2 text-center">
            <span>{{$t('insights.No_departments_reached')}}</span>
          </div>
        </template>
      </b-table>
    </CCol>
  </CRow>
</template>

<script>

export default {
  name: 'TopicsInsightsDepTable',
  props: ['departments'],
  data() {
    return {
      departmentData: [],
      isPaginated: true,
      isPaginationSimple: false,
      paginationPosition: 'bottom',
      currentPage: 1,
      perPage: 15,
      sortIcon: 'arrow-up',
      sortIconSize: 'is-small',      
      searchEnabled: false
    }
  },
  methods: {
    getTwemoji(emoji) {
      return twemoji.parse(emoji);
    }
  },
  mounted:function() {
		// Set the prop as the table data
		this.departmentData = Object.values(this.departments);
    // Check if the pagination should be activated
    (this.departmentData.length > this.perPage) ? this.isPaginated = true : this.isPaginated = false;    
  }
}
</script>
